import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private service: MessageService) {}
    showInfoViaToast(details: string, duration: number = 60 * 1000) {
        this.service.add({ severity: 'info', summary: 'Info', detail: details, life: duration });
    }

    showWarnViaToast(details: string, duration: number = 60 * 1000) {
        this.service.add({ severity: 'warn', summary: 'Warn', detail: details, life: duration });
    }

    showErrorViaToast(details: string, duration: number = 60 * 1000) {
        this.service.add({ severity: 'error', summary: `Error`, detail: details, life: duration });
    }

    showSuccessViaToast(details: string, duration: number = 60 * 1000) {
        this.service.add({ severity: 'success', summary: `Success`, detail: details, life: duration });
    }

    formateErrorMessage(err: HttpErrorResponse): string {
        if (err.status == 0) {
            return 'An Error Occured, Please Contact Backend Team';
        }
        if (err.error.locale) {
            return `${err.error.locale.ar} -- ${err.error.locale.en}`;
        }

        return err.error.msg!;
    }
}
